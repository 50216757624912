.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.speaker-title {
  font-family: Urbanist, sans-serif;
  font-size: 6em;
  font-weight: 600;
  line-height: 153.6px;
  text-align: left;
  color: white;
  margin-bottom: 20px;
}

.speaker-text {

  font-family: Urbanist;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.04em;
  color: #D5CFFF;
  text-align: right;

}

.speaker-body {
  overflow-x: hidden;
}


.separator {
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 20px 0;
  /* Adjust spacing as needed */
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.gradient-container {
  background: linear-gradient(180deg, #132b84, #6556b5, #2a2f8a, #320a60, #1a083b); /* Example gradient */
}


.custom-shell-bullet {
  background-image: url('../public/images/ShellBullet.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 1.75em;
  padding-left: 3em;
  /* margin-bottom: 0.5em; */
  color: #D5CFFF;
}

.custom-shell-bullet-alt {
  background-image: url('../public/images/ShellBulletAlt.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 1.75em;
  padding-left: 3em;
  /* margin-bottom: 0.5em; */
  color: #8C81EF;
}

.glow {
  text-shadow: #FFF 0 0 10px;
}

.waves-container{
  position: absolute;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.wave {
  background: url('../public/images/waves/wave1.png');
  position: absolute;
  left:0;
  width: 100%;
  height: 100%;
  background-size: 1000px 100px;

}
.wave1 {
  animation: animate 11s linear infinite;
  animation-delay: -3.9s;
  bottom:0;
  z-index: 0;
}
.wave2 {
  animation: animate 7s linear infinite;
  animation-delay: -3.8s;
  bottom:0;
  z-index: 0;
  opacity: 0.5;

}
@keyframes animate {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1000px;
  }
  
}

.buoy {
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  scale: 200%;
  transform-origin: 75% 95%; /* 100px from left, 50px from top */
  right: 15vw;
  overflow: hidden;
  bottom: -4vw;
  animation-delay: -0.5s;
  -webkit-transform-style: preserve-3d; /* Safari fix */
  will-change: transform; /* Performance optimization */
  animation: tilt 5.45s infinite alternate ease-in-out; /* Animation name, duration, repeat, and easing */
}

@keyframes tilt {
  0% {
      transform: rotate(10deg) translateY(-5vh); /* Left */
  }
  100% {
      transform: rotate(-10deg) translateY(1vh);
  }
}

@media (max-width: 768px) {
  .custom-shell-bullet,
  .custom-shell-bullet-alt {
    background-size: 2vw;
    padding-left: 2.5vw;
  }
}

.strobe1 {
  transform-origin: 90% 0%;
  animation: tilt 3s infinite alternate ease-in-out;
}

.strobe2 {
  transform-origin: 90% 0%;
  animation: tilt 4s infinite alternate ease-in-out;
}

.strobe3 {
  transform-origin: 0% 5%;
  animation: tilt 4s infinite alternate ease-in-out;
}

.float1 {
  animation: updown 3s infinite alternate ease-in-out;
}
.float2 {
  animation: updown 4s infinite alternate ease-in-out;
}

@keyframes updown {
  0% {
      transform: translateY(-2vw);
  }
  100% {
      transform: translateY(0vw);
  }
}